@import '~@aurora/typography/a-typography';
@import '~@aurora/theme/mixins';
@import '~@aurora/form/a-text-input/a-input';

.input-responsive {
    width: 100%;
    max-width: 350px;
}

.input-margin--topReset {
    margin-top: 0;
}

.input-margin--bottom {
    margin-bottom: 20px;
}

.confirm-password--errorText {
    @include a-theme-prop(color, state-danger);
}

.activate-account--alert {
    margin-top: 20px;
    margin-bottom: 20px;

    /* stylelint-disable */
    > svg[class^='Icon'] {
        display: none;
    }

    > div[class^='Alert'] {
        display: flex;
        align-items: center;
    }
    /* stylelint-enable */
}

.activate-account--alert-icon {
    @include a-theme-prop(color, state-danger);
    margin-right: 10px;
}
