@import 'variables';

@import '~@aurora/typography/a-typography';
@import '~@aurora/icon/a-icon';
@import 'bidi-prop';

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
}

html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

.root {
    height: 100%;

    /* Cognito wrapper */
    [class^='Section__container'] {
        height: 100%;
    }
}

.identity-app-root {
    ul,
    li {
        margin: 0;
        list-style: none;
    }

    p {
        margin: 0;
    }
}

// Application level bug fix for elements
// that recieve focus by mouse click.
// See more: https://jira.globoforce.com/browse/REC-1310
//           https://github.com/WICG/focus-visible

.js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
}
