@import '../../variables';

.emailConfirmationSent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.emailConfirmationSent--have-account {
    margin: 40px 0 $double-unit;
}

.emailConfirmationSent--info-panel {
    max-width: 548px;
}

.resendConfirmation--button {
    margin: 25px 0;

    /* stylelint-disable */
    svg[class^='Icon'] {
        display: none;
    }
    /* stylelint-enable */
}

.resendConfirmation--icon {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
    font-size: 18px;
    fill: currentColor;
}

.emailConfirmationSent--loader {
    margin: 18px 0;
}
