.resetRequired {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.resetRequired--accountLink {
    margin-top: 40px;
}
