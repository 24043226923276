@import '~@aurora/typography/a-typography';
@import '~@aurora/form/a-checkbox/a-checkbox';
@import '~@aurora/form/a-text-input/a-input';

.signup-body {
    width: 100%;
    max-width: 350px;
}

.auth-form-headline {
    margin-top: 0;
    margin-bottom: 20px;
}

.input-responsive {
    width: 100%;
    max-width: 350px;
}

.signup-helper--text {
    @include a-theme-prop(color, text-secondary);

    margin-top: 5px;
    margin-bottom: 5px;
}

.signup-helper--errorText {
    @include a-theme-prop(color, state-danger);
}

.input-margin--bottom {
    margin-bottom: 20px;
}

.input-margin--topReset {
    margin-top: 0;
}

.signup-login--text {
    @include a-theme-prop(color, text-secondary);
    margin-top: 60px;
    text-align: left;
}

.signup-login--link {
    @include a-theme-prop(color, primary);
    margin: 0 5px;
}

.signup-link--color {
    @include a-theme-prop(color, primary);
}

.signup-checkbox-icon {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 16px;
    height: 16px;
    fill: #fff;
}

.signup-checkbox-text--align {
    margin-top: 10px;
}

.signup-checkbox-icon--align {
    margin-top: 15px;
}

.signup-link--color {
    @include a-theme-prop(color, primary);
}
