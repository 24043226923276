@import '~@aurora/theme/mixins';
@import '../../../variables';

.alreadyHaveAccount {
    margin: 10px 0 $double-unit;
    text-align: center;
    font-size: 0.9rem;
}

.alreadyHaveAccount--text {
    @include a-theme-prop(color, text-secondary);
    margin-right: $half-unit;
}

.alreadyHaveAccount--link {
    @include a-theme-prop(color, primary);
}
