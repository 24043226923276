@import 'src/variables';

/*
* Application Responsive Shell
*/

.identity-app-root {
    display: flex;
    width: 100%;
    height: 100%;
}

.app-auth-container-with-side-panel {
    width: 100%;

    @media (min-width: $desktop-breakpoint) {
        width: 50%;
    }

    @media (min-width: $content-breakpoint) {
        width: 55%;
    }
}

.app-auth-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    /*
    * Set height: auto for small mobile screen on pages without side panel (like Error pages, Notifications)
    */
    @media (max-width: $mobile-full-width-breakpoint) {
        .app-auth-section {
            flex: 1;
            height: auto;
            min-height: auto;
        }
    }

    /*
    * Hide Language selector, if empty container is present on pages without side panel (like Error pages, Notifications)
    */
    .app-auth-language {
        display: none;
    }
}

.app-auth-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    padding-top: 35px;
    padding-right: 30px;
    padding-left: 30px;

    @media (min-width: $mobile-full-width-breakpoint) {
        margin-bottom: 45px;
        padding-right: 45px;
        padding-left: 45px;
    }
}

.app-auth-logo {
    max-width: 100%;
}

.app-auth-logo--svg {
    display: block;
    width: 211px;
    height: 57px;
}

.app-auth-section {
    height: calc(100% - #{$header-full-height-mobile}* 2);
    min-height: 490px;

    @media (min-width: $mobile-full-width-breakpoint) {
        height: calc(100% - #{$header-full-height-desktop}* 2);
    }
}

.app-auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.auth-app-form {
    display: flex;
    justify-content: center;
    min-width: 100%;
    max-width: 350px;
    padding-right: 30px;
    padding-left: 30px;
}

.app-auth-language {
    display: flex;
    align-items: flex-end;
    height: $header-full-height-mobile;
    padding-right: 30px;
    padding-bottom: 45px;
    padding-left: 30px;

    @media (min-width: $mobile-full-width-breakpoint) {
        height: $header-full-height-desktop;
        padding-right: 45px;
        padding-left: 45px;
    }
}
