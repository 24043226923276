@import 'src/variables';
@import '~@aurora/theme/a-theme';

.languageSelector {
    width: 100%;

    /* stylelint-disable-next-line */
    button[class*='Select_a-select-button'] {
        width: 100%;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    /* stylelint-disable-next-line */
    button[class*='Select_a-select-button--shown'] {
        border-bottom-color: $a-theme-primary;
    }

    /*
    * Open UP list with language options
    */
    /* stylelint-disable-next-line */
    ul[class^='Listbox'] {
        top: auto;
        bottom: 100%;
        border-top-width: 1px;
        border-bottom-width: 0;
        border-top-left-radius: 3px;

        border-top-right-radius: 3px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    @media (min-width: $mobile-full-width-breakpoint) {
        display: inline-flex;
        width: auto;

        /* stylelint-disable-next-line */
        button[class*='Select_a-select-button'] {
            width: 250px;
        }
    }
}

.languagePicker-arrow {
    position: absolute;
    z-index: 1;
    top: 13px;
    right: 9px;
    transform: scaleY(-1);

    .languageSelector > [aria-expanded='true'] & {
        transform: none;
    }
}
