@import '@aurora/typography/a-typography';
@import '../../variables';
@import '../../bidi-prop';

.resendConfirmation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.resendConfirmation--description {
    margin-bottom: $space-unit;
}

.resendConfirmation--button {
    margin: $space-unit 0;
}
