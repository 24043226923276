@import '~@aurora/theme/mixins';
@import '~@aurora/typography/a-typography';

.login--passwordLink {
    @include a-theme-prop(color, primary);
    margin-bottom: 5px;

    cursor: pointer;
    text-decoration: underline;
}

.login--noAccount {
    margin-top: 60px;
    text-align: left;
}

.login--alert {
    margin-bottom: 20px;

    > svg {
        display: none;
    }

    > div {
        display: flex;
        align-items: center;
    }
}

.login--alert-icon {
    @include a-theme-prop(color, state-danger);
    margin-right: 10px;
}
