@import 'src/variables';
@import '~@aurora/typography/a-typography';

.auth-welcome-container {
    display: none;
    align-items: center;
    width: 45%;
    height: 100%;

    @media (min-width: $desktop-breakpoint) {
        display: flex;
        width: 50%;
    }

    @media (min-width: $content-breakpoint) {
        width: 45%;
    }
}

.auth-welcome-bg {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background-color: #c43f58;
    background-image: url('../../assets/auth-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.auth-welcome-container--message {
    flex: 1;
    padding: 0 5%;
    text-align: center;
    color: #fff;
}

.auth-welcome-heading {
    font-size: 3.5rem;
}
