@import '~@aurora/typography/a-typography';
@import '~@aurora/theme/mixins';
@import '~@aurora/form/a-text-input/a-input';

.authFormBody {
    width: 100%;
    max-width: 350px;
}

.authForm-headline {
    margin-top: 0;
    margin-bottom: 20px;
}

.input-responsive {
    width: 100%;
    max-width: 350px;
}

.password-helper--text {
    @include a-theme-prop(color, text-secondary);

    margin-top: 5px;
    margin-bottom: 5px;
}

.password-helper--errorText {
    @include a-theme-prop(color, state-danger);
}

.input-margin--bottom {
    margin-bottom: 20px;
}

.input-margin--topReset {
    margin-top: 0;
}

.authForm-password-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}
