@import '../../variables';

.instructionsSent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 548px;
}

.instructionsSent--have-account {
    margin: 40px 0 $double-unit;
}
