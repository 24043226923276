/*
* RESPONSIVE BREAKPOINTS
*/
$mobile-full-width-breakpoint: 530px;
$desktop-breakpoint: 780px;
$content-breakpoint: 1024px;

/*
* CONSTANTS
*/
$header-height: 92px;
$header-full-height-mobile: 117px;
$header-full-height-desktop: 137px;

$space-unit: 8px;
$half-unit: $space-unit / 2; /*4px*/
$double-unit: 2 * $space-unit; /*16px*/
$triple-unit: 3 * $space-unit; /*24px*/
$quadruple-unit: 4 * $space-unit; /*32px*/
$quintuple-unit: 5 * $space-unit; /*40px*/
$sextuple-unit: 6 * $space-unit; /*48px*/
$septuple-unit: 7 * $space-unit; /*56px*/
$octuple-unit: 8 * $space-unit; /*64px*/
$nonuple-unit: 9 * $space-unit; /*72px*/
