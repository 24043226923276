@import '~@aurora/theme/a-theme';

.spinner-main {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
}

.spinner-content {
    position: absolute;
    display: block;
    width: 25px;
    height: 25px;
    animation: animation-spin 0.7s linear infinite;
    border: 2px solid #fff;
    border-top-color: $a-theme-primary;
    border-right-color: $a-theme-primary;
    border-radius: 50%;
}

@keyframes animation-spin {
    100% {
        transform: rotate(360deg);
    }
}
